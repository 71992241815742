import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import "../../index.css"; // Ensure this path correctly points to your main index.css file
import { useNavigate } from "react-router-dom";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle login logic here
    console.log("Email:", email);
    console.log("Password:", password);
    // onLogin(); // Trigger the login state change
    navigate("/jobopenings");
    // navigate("/cvview/fd5c8961d5d8366fb7bb16730da15aa16669c7cceb97483f46917a796c20cfb372005202732053c92de83826686e658bcc730c7a25d120fdce550a3c6a3d74bc");
  };

  useEffect(() => {
    // Check if the cookie already exists
    let uniqueId = Cookies.get("uniqueId");

    // If not, create a new cookie with a unique ID and an expiry of 7 days
    if (!uniqueId) {
      uniqueId = uuidv4(); // Generate a unique ID
      Cookies.set("uniqueId", uniqueId, { expires: 7 }); // Set the cookie with a 7-day expiry
      console.log("New cookie created:", uniqueId);
    } else {
      console.log("Existing cookie found:", uniqueId);
    }
  }, []);

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-white">
      <div
        className="flex flex-col items-center justify-center lg:w-3/5 w-full lg:h-full"
        style={{ backgroundColor: "#D0DDE4" }}
      >
        <div><img src="./Images/login_bg.png" alt="background"/></div>
        <div className="mt-10 text-lg text-[#6491A9] font-bold w-80">Enhance Your Recruitment with AI-Powered Resume Compatibility</div>
      </div>
      <div className="flex flex-col justify-center items-center bg-white text-gray-700 lg:w-2/5 w-full p-8">
        <div className="w-full text-center">
          <div className="flex justify-center items-center h-32">
            <img
              src="/Images/favicon_career.svg"
              alt="Add Agent"
              className="w-20 mb-4"
            />
          </div>
          <h2 className="text-4xl font-bold mb-2">Managing AI books</h2>
          <p className="text-lg mb-6 text-gray-500">
            Start publishing your website links
          </p>
          <form onSubmit={handleSubmit} className="w-full">
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-left"
                htmlFor="email"
              >
                Email ID
              </label>
              <input
                type="email"
                id="email"
                className="w-full mt-1 p-2 border border-gray-300 rounded-xl"
                placeholder="agentname@manager.io"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-medium text-left"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full mt-1 p-2 border border-gray-300 rounded-xl"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center">
                <input type="checkbox" id="remember" className="mr-2" />
                <label className="text-sm" htmlFor="remember">
                  Remember me
                </label>
              </div>
              <button type="button" className="text-sm text-blue-500">
                Forgot Password?
              </button>
            </div>

            <button
              type="submit"
              className="w-full p-4 text-white rounded-xl text-lg"
              style={{ backgroundColor: "#22A8E1" }}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
