import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
// import Navbar from "./components/Common/Navbar";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import JobOpenings from "./components/JobOpenings/JobOpenings";
import Candidates from "./components/Candidates/Candidates";
import JobDetails from "./components/JobOpenings/JobDetails/JobDetails";

function App() {
  return (
    <Router>
      <div className="App flex">
        <div className="h-full w-full">
          {/* <Navbar /> */}
          {/* <div className=""> */}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/jobopenings" element={<JobOpenings />} />
            <Route path="/jobDetails" element={<JobDetails />} />
            <Route path="/candidates" element={<Candidates />} />
            <Route path="/cvview/:value" element={<Home />} />
            <Route path="*" element={<Login />} />
          </Routes>
          {/* </div> */}
        </div>
      </div>
    </Router>
  );
}

export default App;
