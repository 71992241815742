import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  gridClasses,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { red } from "@mui/material/colors";

import apiService from "../../service";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/SideBar";
import Footer from "../Common/footer";
import { Link } from "react-router-dom";
import CreateJobForm from "./CreateJDForm";
import stringToColor from "../../services/stringToColor";


const columns: GridColDef[] = [
  {
    field: "jobTitle",
    headerName: "Job Title",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    renderCell: (params) => (
      <Link
        to="/jobDetails"
        className="flex items-left p- w-full text-[#6491A9] font-[600] transition-colors no-underline"
      >
        <span>{params.value}</span>
      </Link>
    ),
  },
  {
    field: "totalExperience",
    headerName: "Total experience",
    headerClassName: "headerBackground",
    headerAlign: "left",
    align: "left",
    justifyContent: "left",
    sortable: true,
  },
  {
    field: "Department",
    headerName: "Department",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
  },
  {
    field: "Client",
    headerName: "Client",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    renderCell: (params) => (
      <div className="flex flex-nowrap justify-left align-middle w-full">
        <div className="mr-1">
          <img src={params.value.logo} alt="logo" />
        </div>
        <div> {params.value.name}</div>
      </div>
    ),
  },
  {
    field: "Openings",
    headerName: "Openings",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
  },
  {
    field: "Candidates",
    headerName: "Candidates",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
  },
  {
    field: "Status",
    headerName: "Status",
    headerClassName: "headerBackground",
    headerAlign: "center",
    sortable: true,

    renderCell: (params) => {
      let backgroundColor;
      let textColor;
      switch (params.value) {
        case "In-Review":
          backgroundColor = "#E5EFF4";
          textColor = "#6491A9";
          break;

        case "Withdrawn":
          backgroundColor = "#F6F6F6";
          textColor = "#9B9B9B";
          break;

        default:
          backgroundColor = "transparent";
          textColor = "#5F92DD";
      }
      return (
        <div className="text-center">
          <span
            style={{
              backgroundColor,
              color: textColor,
              padding: "5px 20px",
              borderRadius: "15px",
              textAlign: "center",
              textWrap: "nowrap",
              width: "100%",
            }}
          >
            {params.value}
          </span>
        </div>
      );
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
    renderCell: (params) => {
      // Generate a consistent background color for the initial based on the name
      const backgroundColor = stringToColor(params.value);
      return (
        <div className="flex items-center space-x-2">
          <div
            className="w-7 h-7 text-white rounded-full flex items-center justify-center font-bold"
            style={{ backgroundColor }}
          >
            {params.value.charAt(0)}
          </div>
          <span className="text-[#446271] font-normal">{params.value}</span>
        </div>
      );
    },
  },
  {
    field: "createdOn",
    headerName: "Created On",
    headerClassName: "headerBackground",
    headerAlign: "left",
    sortable: true,
  },
  {
    field: "Actions",
    headerName: "Actions",
    headerClassName: "headerBackground",
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (params) => (
      <div>
        <IconButton aria-label="view">
          <BorderColorIcon fontSize="small" />
        </IconButton>

        <IconButton aria-label="delete">
          <DeleteOutlineIcon fontSize="small" sx={{ color: [red[400]] }} />
        </IconButton>
      </div>
    ),
  },
];

const rows = [
  {
    id: 1,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Moonmoon Roy",
    createdOn: "26 Aug 2024",
  },
  {
    id: 2,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 12,
    Candidates: 100,
    Status: "Withdrawn",
    createdBy: "Neeraja",
    createdOn: "26 Aug 2024",
  },
  {
    id: 3,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Pooja",
    createdOn: "26 Aug 2024",
  },
  {
    id: 4,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Neeraja",
    createdOn: "26 Aug 2024",
  },
  {
    id: 5,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Moonmoon Roy",
    createdOn: "26 Aug 2024",
  },
  {
    id: 6,
    jobTitle: "Lead Developer",
    totalExperience: "6+ years",
    Department: "Engineering",
    Client: { name: "AgilizTech", logo: "/Images/AgilizTechLogo.png" },
    Openings: 2,
    Candidates: 2,
    Status: "In-Review",
    createdBy: "Moonmoon Roy",
    createdOn: "26 Aug 2024",
  },
];

function JobOpenings() {
  const [jobList, setJobList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (data) => {
    setFormData(data);
    setIsModalOpen(false);
    console.log("Received Form Data:", data);
    // Add further processing of form data here
  };

  useEffect(() => {
    const jobopenings = async () => {
      try {
        const response = await apiService.getJobOpeninigs();
        console.log(response);
        if (response && response.message === "Successful") {
          setJobList(response.data);
        } else {
          setJobList(rows);
        }
      } catch (error) {
        console.log(error);
        setJobList(rows);
      }
    };

    jobopenings();
    console.log(jobList);
  }, []);

  const updatedColumns = columns.map((column) => {
    // Adjust column width for specific columns
    if (column.field === "createdBy") {
      return {
        ...column,
        flex: 1.5, // Increase flex to occupy more space
        minWidth: 150, // Set minimum width if needed
      };
    }
    return {
      ...column,
      flex: 1, // Default flex for other columns
      minWidth: 100, // Set a consistent minWidth
    };
  });

  return (
    <div className="flex-grow">
      <Navbar />
      <div className="flex w-full justify-evenly">
        <div className="bg-[#7A8286] w-[12%]">
          <Sidebar />
        </div>
        <div className="w-[88%]">
          <div className="flex-grow  p-6">
            <div className="flex justify-between items-center align-middle mb-4">
              <div className=" w-1/3 items-center">
                <div className="text-lg font-semibold text-[#6491A9] text-left open-sans-600">
                  Job Openings
                </div>
                {/* <p className="text-gray-500">Listing of website to publish</p> */}
              </div>
              <div className=" w-2/3">
                <div className="flex justify-end items-center">
                  <div>
                    <button
                      onClick={handleOpenModal}
                      className="bg-[#6491A9] rounded-lg text-lg font-normal text-white p-2 px-5 open-sans-400"
                    >
                      Create JD
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Box sx={{ width: "100%", typography: "body" }}>
                {jobList && jobList.length > 0 && (
                  <DataGrid
                    rows={jobList}
                    columns={updatedColumns}
                    getCellClassName={(
                      params: GridCellParams<any, any, number>
                    ) => {
                      if (
                        params.field === "Status" ||
                        params.field === "Actions" ||
                        params.value == null ||
                        params.field === "__check__"
                      ) {
                        return "";
                      }
                      // Check if params.value is a number (integer or decimal)
                      else if (
                        typeof params.value === "number" &&
                        !isNaN(params.value)
                      ) {
                        return "numberCellRight";
                      }

                      // Set className for other types (like text)
                      return "textCellleft";
                    }}
                    autoHeight
                    getRowHeight={() => "auto"} // Set row height to auto
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    sx={{
                      width: "100%",
                      fontSize: "12px",
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#F4F8FA !important",
                        color: "#42526D",
                        // textAlign: "center",
                        fontSize: "14px",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#F4F8FA !important",
                        // textAlign: "center",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "#42526D",
                        fontWeight: "500",
                        // textAlign: "center",
                        fontFamily: "work sans",
                      },
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          backgroundColor: "#F4F8FA !important",
                          color: "#42526D !important",
                          // textAlign: "center",
                        },
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // textAlign: "center",
                        fontFamily: "work sans",
                      },
                      "& .MuiTablePagination-root p": {
                        marginBottom: 0,
                      },
                    }}
                  />
                )}
                {(!jobList || jobList.length === 0) && <div>No Data Found</div>}
              </Box>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CreateJobForm onClose={handleCloseModal} onSubmit={handleFormSubmit} />
      )}
      <Footer />
    </div>
  );
}

export default JobOpenings;
