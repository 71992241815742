import React, { useState } from "react";
import { Modal, Box, TextField, Button, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GenerateLinkModal = ({ open, onClose }) => {
  const [link] = useState(
    "http://www.company.com:81/a/b/c.html?user=Alice&year=2008#p2"
  );

  // Function to copy the link to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  };

  return (
    <Modal open={open} onClose={onClose} className="generateLinksCustom">
      <Box className="bg-white p-10 rounded-lg shadow-lg mx-auto my-20 min-w-max max-w-xl">
        {/* <div className="modal-header flex justify-between bg-[#D0DDE4] p-2 items-center mb-2">
          <h2>Generate Link</h2>
          <button onClick={onClose} className="close-button">
            &times;
          </button>
        </div> */}
         <h2 className="text-center text-xl font-semibold mb-4">Generate Link</h2>
        <div className="m-8 flex justify-center items-center">
          <img src="/Images/unlink.png" alt="link" />
        </div>
        <p className="text-center mb-4">
          Unique link generated for{" "}
          <strong>Gaurav Singh_Full stack developer</strong>
        </p>
        <div className="flex items-center mb-2">
          <TextField
            value={link}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton onClick={copyToClipboard}>
                  <ContentCopyIcon />
                </IconButton>
              ),
            }}
          />
        </div>
        <p className="text-sm text-left mb-4 text-[#9CA0A4]">
          Copy and share this link for further processing of the application
        </p>
        <div className="flex gap-2 mb-4 p-3 bg-[#F4F6F7] rounded">
          <TextField
            placeholder="Share this link to specific people by email"
            fullWidth
            variant="outlined"
            size="small"
          />
          <Button variant="contained" className="shareLinkBtn  text-gray-700">
            Share
          </Button>
        </div>
        <div className="flex justify-center gap-4">
          <Button
            onClick={onClose}
            variant="outlined"
            className="text-gray-700 border-gray-300"
          >
            Back
          </Button>
          <Button
            onClick={copyToClipboard}
            variant="contained"
            className="cpyLinkBtn text-white"
          >
            Copy Link
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default GenerateLinkModal;
