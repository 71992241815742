import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../service";
import PdfViewer from "../PDF_Viewer/Pdf_Viewer";
import Chats from "../Chat/Chats";

import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import Footer from "../Common/footer";
import Navbar from "../Common/Navbar";

const Home = () => {
  const { value } = useParams();
  const [pdf, setPdf] = useState("");
  const [score, setScore] = useState("");

  console.log(value);
  useEffect(() => {
    // Check if the cookie already exists
    let uniqueId = Cookies.get("uniqueId");

    // If not, create a new cookie with a unique ID and an expiry of 7 days
    if (!uniqueId) {
      uniqueId = uuidv4(); // Generate a unique ID
      Cookies.set("uniqueId", uniqueId, { expires: 7 }); // Set the cookie with a 7-day expiry
      console.log("New cookie created:", uniqueId);
    } else {
      console.log("Existing cookie found:", uniqueId);
    }

    async function fetchDetails() {
      const result = await apiService.getCandidateInfo(value);
      setPdf(result.candidate);
      let scoreData = result.score;
      scoreData.candidateInfo = result.candidate;
      scoreData.uniqueId = uniqueId;
      scoreData.token = value;
      setScore(scoreData);
      console.log(score);
    }
    fetchDetails();
  }, []);
  return (
    <div className="flex-grow">
      <Navbar />
      <div className="flex items-center justify-start h-12 bg-[#DAE0E3]">
        <div className="ml-10 mr-5">{pdf.firstName}</div>
        {/* <div className="mr-5">FullStack Developer</div>
                <div>9+ years</div> */}
      </div>
      <div className="flex justify-evenly">
        <div className="w-2/4">{pdf && <PdfViewer pdfUrl={pdf} />}</div>
        <div className="w-2/4">{score && <Chats score={score} />}</div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
