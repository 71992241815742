import React, { useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FormControl, MenuItem, Select } from "@mui/material";

function CreateJDForm({ onClose, onSubmit }) {
  // Form state
  const [formValues, setFormValues] = useState({
    jobTitle: "",
    totalExperience: "",
    client: "",
    department: "",
    openings: "",
    jobDescription: "",
  });

  // Sample department data - you can replace this with data from an API or state
  const departments = [
    { id: 1, name: "Engineering" },
    { id: 2, name: "Marketing" },
    { id: 3, name: "Sales" },
    { id: 4, name: "Human Resources" },
  ];

  // Sample client data - you can replace this with data from an API or state
  const clients = [
    { id: 1, name: "Agiliztech" },
    { id: 2, name: "Microsoft" },
    { id: 3, name: "Salesforce" },
    { id: 4, name: "Human Connect" },
  ];

  // Error state for form validation
  const [errors, setErrors] = useState({});

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Form validation function
  const validateForm = () => {
    const newErrors = {};
    if (!formValues.jobTitle) newErrors.jobTitle = "Job Title is required";
    if (!formValues.department) newErrors.department = "Department is required";
    if (!formValues.jobDescription)
      newErrors.jobDescription = "Job Description is required";
    // Add more validation as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formValues); // Send valid form data to parent component
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content customeJdFromWidth">
        <div className="modal-header flex justify-start p-2 items-center open-sans-600">
          <h3>Create JD</h3>
          {/* <button onClick={onClose} className="close-button">
            &times;
          </button> */}
        </div>
        <form onSubmit={handleSubmit} style={{ margin: "20px" }}>
          <div className="flex justify-start">
            <div className="form-group mr-6">
              <label>
                Job Title <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="jobTitle"
                value={formValues.jobTitle}
                onChange={handleChange}
                className="form-input"
              />
              {errors.jobTitle && (
                <span className="error-text">{errors.jobTitle}</span>
              )}
            </div>
            <div className="form-group">
              <label>Total Work Experience</label>
              <input
                type="text"
                name="totalExperience"
                value={formValues.totalExperience}
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>
          <div className="flex justify-around">
            <div className="form-group" style={{ width: "33%" }}>
              <label className="mb-1">Client</label>
              <FormControl fullWidth margin="none" size="small">
                <Select
                  id="client"
                  name="client"
                  value={formValues.client}
                  onChange={handleChange}
                >
                  {clients.map((cli) => (
                    <MenuItem key={cli.id} value={cli.name}>
                      {cli.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="form-group" style={{ width: "33%" }}>
              <label className="mb-1">
                Department <span style={{ color: "red" }}>*</span>
              </label>
              <FormControl fullWidth margin="none" size="small">
                <Select
                  id="department"
                  name="department"
                  value={formValues.department}
                  onChange={handleChange}
                  error={!!errors.department}
                >
                  {departments.map((dept) => (
                    <MenuItem key={dept.id} value={dept.name}>
                      {dept.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.department && (
                <span className="error-text">{errors.department}</span>
              )}
            </div>

            <div className="form-group" style={{ width: "33%" }}>
              <label>Openings</label>
              <input
                type="text"
                name="openings"
                value={formValues.openings}
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="mb-1">
              Paste your Job Description Here{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextareaAutosize
              minRows={3}
              placeholder="Minimum 3 rows"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
              name="jobDescription"
              value={formValues.jobDescription}
              onChange={handleChange}
            />
            {errors.jobDescription && (
              <span className="error-text">{errors.jobDescription}</span>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="submit-button text-[#6491A9] bg-white border border-[#6491A9] p-2 px-3 rounded"
            >
              Back
            </button>

            <button
              type="submit"
              className="submit-button bg-[#6491A9] text-white py-2 px-12 ml-10 rounded"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateJDForm;
