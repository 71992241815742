import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import "./Pdf_Viewer.css";

// Set the workerSrc for pdfjs-dist
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = ({ pdfUrl }) => {
  console.log(pdfUrl);
  const [blobUrl, setBlobUrl] = useState(null);
  const [error, setError] = useState(null);

  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [scale, setScale] = useState(1.0); // Scale for zooming
  // // Handle successful loading of the document
  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  //   setPageNumber(1); // Reset to first page on new load
  // };

  // const zoomIn = () => {
  //   setScale((prevScale) => Math.min(prevScale + 0.25, 3.0)); // Max zoom level
  // };

  // const zoomOut = () => {
  //   setScale((prevScale) => Math.max(prevScale - 0.25, 0.5)); // Min zoom level
  // };

  // const downloadPdf = () => {
  //   const file = pdfUrl.cvLocation;
  //   const link = document.createElement("a");
  //   link.href = file;
  //   link.download = file.split("/").pop(); // Extract the file name from the URL
  //   link.click();
  // };

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await fetch(pdfUrl.cvLocation);
        if (!response.ok) {
          throw new Error("Failed to fetch PDF");
        }
        const pdfData = await response.blob(); // Get the Blob object from the response
        const url = URL.createObjectURL(
          new Blob([pdfData], { type: "application/pdf" })
        );
        setBlobUrl(url);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPdfData();
  }, []);

  return (
    <div className="h-full">
      {/* Dynamicaly render PDF file in Iframe */}
      {error && <p>Error fetching PDF: {error}</p>}
      {blobUrl && (
        <iframe src={blobUrl} width="100%" height="100%" title="pdf" />
      )}

      {/* render using react-pdf */}
      {/* <div>
        <div className="pdf-controls">
          <button
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            Previous
          </button>
          <span>
            Page {pageNumber} of {numPages}
          </span>
          <button
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Next
          </button>
          <button onClick={zoomOut}>Zoom Out</button>
          <button onClick={zoomIn}>Zoom In</button>
        </div>

        <Document file={pdfUrl.cvLocation} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div> */}
    </div>
  );
};

export default PdfViewer;
