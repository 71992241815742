import React from "react";

const Footer = () => {
  return (
    <div className="footer-declaration mt-1 h-12 content-center bg-[#f3fbff]">
      <div>©2024 All rights reserved | Powered by AgilizTech</div>
    </div>
  );
};

export default Footer;
