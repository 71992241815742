// Function to generate a consistent color based on the string input
const stringToColor = (string) => {
    let hash = 0;
    // Create a hash from the string
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the hash to an RGB color
    const color = `#${((hash >> 24) & 0xff).toString(16)}${(
      (hash >> 16) &
      0xff
    ).toString(16)}${((hash >> 8) & 0xff).toString(16)}`.substring(0, 7);
    // Ensure color has 6 characters
    return color.padEnd(7, "0");
  };

  export default stringToColor;