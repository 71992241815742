import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;
let uniqueId = Cookies.get("uniqueId");

const HttpClient = axios.create();

HttpClient.interceptors.request.use(
  (config) => {
    if (uniqueId) {
      config.headers.Cookies = `${uniqueId}`;
    }
    console.log("Axios Request Config:", config);
    return config;
  },
  (error) => Promise.reject(error)
);

const getCandidateInfo = async (token) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log("Axios Request Config:", config); // Log the request config

  try {
    const response = await HttpClient.get(
      `${API_URL}/candidate-info/${token}`,
      config
    );
    console.log(response);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const chatPdf = async (token, text) => {
  try {
    const response = await HttpClient.post(
      `${API_URL}/chat/${token}`,
      {
        question: text,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getJdInfo = async (job_id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/jd-info/${job_id}`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

const getChatHistory = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/chat-history/${id}`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Chat History:", error);
    throw error;
  }
};

const getAllJobOpenings = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/job-openings/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Job Openings:", error);
    throw error;
  }
};

const getCandidateList = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/candidate-list/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

const getJobOpeninigs = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/job-openings-list/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

const getJobDetailsList = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/job-details-list/`, {
      headers: { "Content-Type": "application/json" },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Candidates List:", error);
    throw error;
  }
};

const apiService = {
  getCandidateInfo,
  chatPdf,
  getJdInfo,
  getChatHistory,
  getAllJobOpenings,
  getCandidateList,
  getJobDetailsList,
  getJobOpeninigs,
};

export default apiService;
