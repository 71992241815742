// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-text {
  position: relative;
}

.highlight-text::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 20px;
  background-color: yellow;
  opacity: 0.5;
  z-index: 2;
}


.docHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
}

.pdf-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.pdf-controls button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/PDF_Viewer/Pdf_Viewer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,YAAY;EACZ,wBAAwB;EACxB,YAAY;EACZ,UAAU;AACZ;;;AAGA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,eAAe;AACjB","sourcesContent":[".highlight-text {\n  position: relative;\n}\n\n.highlight-text::after {\n  content: \"\";\n  position: absolute;\n  top: 20%;\n  left: 20%;\n  width: 60%;\n  height: 20px;\n  background-color: yellow;\n  opacity: 0.5;\n  z-index: 2;\n}\n\n\n.docHeader{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 20px;\n    color: white;\n}\n\n.pdf-controls {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n}\n\n.pdf-controls button {\n  padding: 5px 10px;\n  margin: 0 5px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
