import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { red } from "@mui/material/colors";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import apiService from "../../service";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/SideBar";
import Footer from "../Common/footer";

const columns: GridColDef[] = [
  {
    field: "candidateName",
    headerName: "Candidate Name",
    headerClassName: "headerBackground",
    // headerAlign: "center",
    sortable: true,
  },
  {
    field: "designation",
    headerName: "Designation",
    headerClassName: "headerBackground",
    // headerAlign: "center",
    sortable: true,
  },
  {
    field: "contact",
    headerName: "Contact",
    headerClassName: "headerBackground",
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (params) => (
      <div>
        <IconButton aria-label="view">
          <PhoneIcon fontSize="small" />
        </IconButton>

        <IconButton aria-label="delete">
          <EmailOutlinedIcon fontSize="small" />
        </IconButton>
      </div>
    ),
  },
  {
    field: "appliedJobs",
    headerName: "Applied For Job",
    headerClassName: "headerBackground",
    // headerAlign: "center",
    sortable: true,
    renderCell: (params) => (
      <div>
        <div>{params.value.job}</div>
        <div className="text-[10px]">{params.value.city}</div>
      </div>
    ),
  },
  {
    field: "Status",
    headerName: "Status",
    headerClassName: "headerBackground",
    headerAlign: "center",
    sortable: true,

    renderCell: (params) => {
      let backgroundColor;
      let textColor;
      switch (params.value) {
        case "New":
          backgroundColor = "#E5EFF4";
          textColor = "#6491A9";
          break;

        case "Screening":
          backgroundColor = "#EEE2FF";
          textColor = "#794ABD";
          break;

        default:
          backgroundColor = "transparent";
          textColor = "#5F92DD";
      }
      return (
        <div className="text-center">
          <span
            style={{
              backgroundColor,
              color: textColor,
              padding: "5px 20px",
              borderRadius: "15px",
              textAlign: "center",
              textWrap: "nowrap",
              width: "100%",
            }}
          >
            {params.value}
          </span>
        </div>
      );
    },
  },
  {
    field: "source",
    headerName: "Source",
    headerClassName: "headerBackground",
    // headerAlign: "center",
    sortable: true,
  },
  {
    field: "appliedOn",
    headerName: "Applied On",
    headerClassName: "headerBackground",
    // headerAlign: "center",
    sortable: true,
  },
  {
    field: "Actions",
    headerName: "Actions",
    headerClassName: "headerBackground",
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (params) => (
      <div>
        <IconButton aria-label="view">
          <BorderColorIcon fontSize="small" />
        </IconButton>

        <IconButton aria-label="delete">
          <DeleteOutlineIcon fontSize="small" sx={{ color: [red[400]] }} />
        </IconButton>
      </div>
    ),
  },
];

const rows = [
  {
    id: 1,
    candidateName: "Gaurav Singh",
    designation: "Sr. Developer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "New",
    source: "Linkedin",
    appliedOn: "26 Aug 2024",
  },
  {
    id: 2,
    candidateName: "Balveer Singh",
    designation: "UX Desiginer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "Screening",
    source: "Career Page",
    appliedOn: "26 Aug 2024",
  },
  {
    id: 3,
    candidateName: "Gaurav Singh",
    designation: "Sr. Developer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "New",
    source: "Linkedin",
    appliedOn: "26 Aug 2024",
  },
  {
    id: 4,
    candidateName: "Gaurav Singh",
    designation: "Sr. Developer",
    contact: {
      phone: 9876543212,
      email: "test@email.com",
    },
    appliedJobs: {
      job: "Lead Developer",
      city: "Bangalore, KA",
    },
    Status: "New",
    source: "Linkedin",
    appliedOn: "26 Aug 2024",
  },
];

function Candidates() {
  const [candidateList, setCandidateList] = useState([]);

  useEffect(() => {
    const jobopenings = async () => {
      try {
        const response = await apiService.getJobOpeninigs();
        console.log(response);
        if (response && response.message === "Successful") {
          setCandidateList(response.data);
        } else {
          setCandidateList(rows);
        }
      } catch (error) {
        console.log(error);
        setCandidateList(rows);
      }
    };

    jobopenings();
    console.log(candidateList);
  }, []);

  return (
    <div className="flex-grow">
      <Navbar />
      <div className="flex w-full justify-evenly">
        <div className="bg-[#7A8286] w-[12%]">
          <Sidebar />
        </div>
        <div className="w-[88%]">
          <div className="flex-grow  p-6">
            <div className="flex justify-between items-center align-middle mb-4">
              <div className=" w-2/5 items-center flex flex-nowrap">
                <div className="text-lg font-semibold text-[#6491A9] text-left open-sans-600 mr-6 w-1/5">
                  Candidates
                </div>
                <div className="w-3/5">
                  <div className="flex items-center border border-gray-300 rounded-xl px-2 py-1 shadow-sm bg-[#EBF2F6] ">
                    <SearchIcon className="text-gray-500 mr-2" />
                    <InputBase
                      placeholder="Search a candidate by name, email and phone number"
                      className="flex-1 text-sm focus:outline-none "
                    />
                  </div>
                </div>
              </div>
              <div className=" w-2/5 items-center">
                <div className="flex justify-end items-center">
                  <div>
                    <button className="bg-[#6491A9] rounded-lg text-lg font-normal text-white p-2 px-4 open-sans-400">
                      Add Candidate
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Box sx={{ width: "100%", typography: "body" }}>
                {candidateList && candidateList.length > 0 && (
                  <DataGrid
                    rows={candidateList}
                    columns={columns.map((column) => ({
                      ...column,
                      flex: 1, // Makes columns flexible to occupy available space
                      editable: false, // Disable editing for each column
                    }))}
                    getCellClassName={(
                      params: GridCellParams<any, any, number>
                    ) => {
                      if (
                        params.field === "contact" ||
                        params.field === "Status" ||
                        params.field === "Actions" ||
                        params.value == null ||
                        params.field === "__check__"
                      ) {
                        return "";
                      }
                      // Check if params.value is a number (integer or decimal)
                      else if (
                        typeof params.value === "number" &&
                        !isNaN(params.value)
                      ) {
                        return "numberCellRight";
                      }

                      // Set className for other types (like text)
                      return "textCellleft";
                    }}
                    autoHeight
                    getRowHeight={() => "auto"} // Set row height to auto
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    sx={{
                      width: "100%",
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#F4F8FA !important",
                        color: "#42526D",
                        textAlign: "center",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#F4F8FA !important",
                        textAlign: "center",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "#42526D",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                          backgroundColor: "#F4F8FA !important",
                          color: "#42526D !important",
                          textAlign: "center",
                        },
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        color: "#42526D",
                      },
                      "& .MuiTablePagination-root p": {
                        marginBottom: 0,
                      },
                    }}
                  />
                )}
                {(!candidateList || candidateList.length === 0) && (
                  <div>No Data Found</div>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Candidates;
